import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="eCommerce">
    <section>
      {/*
      <h3>Professional Services</h3>
      <div className="img-container text-center">
        <img className="img-responsive" src="/images/Professional-Services-LP-Diagram-2.png" alt="Professional Services"/>
      </div>
      <p className="section-subheading">Quantic Professional Services offers complete consulting for businesses of small, medium and large Fortune 500 corporations. Quantic Professional Service engagements starts with an assessment of your current IT environments, data storage, data backup and disaster recovery strategies.</p>

      <section>
      </section>
*/}

      <h3>It’s the e-commerce revolution. Blur the lines with omni-channel.</h3>
      <p>Develop an interconnectedness between your e-commerce and store channels blurring the distinction for your customer. Migrate from multi-channel to omni-channel with help from the retail industry’s top consultants.</p>

    </section>
  </Layout>
);

export default Page;
